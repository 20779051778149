import { useMemo } from "react";

import { OrdinalColorScaleConfig } from "@nivo/colors";
import { Theme } from "@nivo/core";

import { useTheme } from "./useTheme";
import { useThemePalette } from "./useThemePalette";

export const useNivoTheme = () => {
    const theme = useThemePalette();
    const { currentTheme } = useTheme();
    return useMemo(() => {
        const chartColours: OrdinalColorScaleConfig = { scheme: "set3" };

        const chartTheme: Theme = {
            tooltip: {
                container: {
                    // TODO: this is only working on bar charts
                    backgroundColor: theme.backgroundColourAlt,
                },
            },
            grid: {
                line: {
                    stroke:
                        currentTheme === "dark"
                            ? "rgba(255, 255, 255, 0.25)"
                            : "rgba(0, 0, 0, 0.25)",
                },
            },
            axis: {
                ticks: {
                    text: {
                        fill: theme.textColour,
                    },
                },
            },
        };

        return { chartColours, chartTheme };
    }, [theme, currentTheme]);
};
