import { ColProps } from "antd/lib/grid";
import React from "react";

export type FormContextData = {
    readonly: boolean;
    labelCol?: ColProps;
    wrapperCol?: ColProps;
};

export const FormContext = React.createContext<FormContextData | null>(null);
