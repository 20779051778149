import { useEffect } from "react";

export const useCheckScrollEnd = (
    containerRef: React.MutableRefObject<HTMLDivElement>,
    onDocumentViewed: () => void
) => {
    useEffect(() => {
        const handleScroll = () => {
            const tolerance = 240; // so that we dont have to scroll to the bottom bottom
            const { offsetHeight, scrollTop, scrollHeight } = containerRef.current;
            const atScrollEnd = offsetHeight + scrollTop >= scrollHeight - tolerance;
            // Trigger document scrolled to bottom of document
            if (atScrollEnd && onDocumentViewed) {
                onDocumentViewed();
            }
        };
        containerRef.current?.addEventListener("scroll", handleScroll);
        return () => {
            containerRef.current?.removeEventListener("scroll", handleScroll);
        };
    }, [onDocumentViewed]);
};
