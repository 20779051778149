import React from "react";
import { connect } from "react-redux";

import { RootState } from "@/state";
import { policyNewBusinessTypesSelector } from "@/state/lookups/client";
import { PolicyNewBusinessType } from "@/state/lookups/client/policyNewBusinessTypes/types";

type Props = {
    policyNewBusinessTypes: PolicyNewBusinessType[];
    policyNewBusinessTypeId: string;
};

const PolicyNewBusinessTypeNameComponent: React.FC<Props> = (props: Props) => {
    const { policyNewBusinessTypes, policyNewBusinessTypeId } = props;

    const policyNewBusinessType = policyNewBusinessTypes.find(
        (u) => u.id === policyNewBusinessTypeId
    );

    if (!policyNewBusinessType) return <span />;

    return <span>{policyNewBusinessType.name}</span>;
};

const mapStateToProps = (state: RootState) => {
    const policyNewBusinessTypesState = policyNewBusinessTypesSelector(state);

    return {
        policyNewBusinessTypes: policyNewBusinessTypesState.items,
    };
};

const PolicyNewBusinessTypeName = connect(mapStateToProps)(PolicyNewBusinessTypeNameComponent);

export { PolicyNewBusinessTypeName };
