import { getStore, history } from "@/state/configureStore";
import { ReduxRouter } from "@lagunovsky/redux-react-router";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import FullStory from "react-fullstory";
import { Provider } from "react-redux";
import RenderInBrowser from "react-render-in-browser";
import { ViewportProvider } from "./contexts/ViewportContext";
import BrowserNotSupported from "./layout/BrowserNotSupported";
import Startup from "./layout/Startup";
import Routes from "./routes/Routes";

const { store } = getStore();

Sentry.init({
    dsn: __SENTRY_DNS__,
    environment: __ENVIRONMENT__,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
});

const App: React.FC = () => {
    return (
        <>
            <FullStory org={__FULLSTORY_KEY__} />
            <RenderInBrowser ie except>
                <ViewportProvider>
                    <AppInner />
                </ViewportProvider>
            </RenderInBrowser>
            <RenderInBrowser ie only>
                <BrowserNotSupported />
            </RenderInBrowser>
        </>
    );
};

const AppInner: React.FC = () => {
    return (
        <Provider store={store}>
            <ReduxRouter history={history}>
                <Startup>
                    <Routes />
                </Startup>
            </ReduxRouter>
        </Provider>
    );
};

export default App;
