import { createSelector } from "reselect";

import { RootState } from "@/state";

import { PolicyProductType, PolicyProductTypeListState } from "../types";

const rootSelector = (state: RootState): PolicyProductTypeListState =>
    state.lookups.client.policyProductTypes.list;

export const policyProductTypesSelector: (state: RootState) => PolicyProductTypeListState =
    createSelector(rootSelector, (root) => root);

export const policyProductTypeItemsSelector: (state: RootState) => PolicyProductType[] =
    createSelector(policyProductTypesSelector, (root) => root.items);
