import { createSelector } from "reselect";

import { RootState } from "@/state";

import { MarritalStatus, MarritalStatusListState } from "../types";

const rootSelector = (state: RootState): MarritalStatusListState =>
    state.lookups.client.marritalStatus.list;

export const marritalStatusSelector: (state: RootState) => MarritalStatusListState = createSelector(
    rootSelector,
    (root) => root
);

export const marritalStatusItemsSelector: (state: RootState) => MarritalStatus[] = createSelector(
    marritalStatusSelector,
    (root) => root.items
);
