import { hasUseCase } from "@/app/identity";
import { Filters, PageOptions, SortOptions } from "@/app/table";
import { getDefaultPageOptions } from "@/app/table/defaults";
import { usecaseSelector } from "@/state/auth";
import { Table as TableAD } from "antd";
import { ColumnProps } from "antd/lib/table";
import {
    Key,
    SorterResult,
    TablePaginationConfig,
    TableRowSelection,
} from "antd/lib/table/interface";
import { PanelRender } from "rc-table/lib/interface";
import React from "react";
import { useSelector } from "react-redux";

const defaultPageOptions = getDefaultPageOptions();

type Props<T extends object> = {
    columns: ColumnProps<T>[];
    dataSource: T[];
    rowKey: keyof T;
    loading?: boolean;
    onRowClick?: (record: T, index: number) => void;
    onRowClickRequiredUseCase?: string;
    externalDataSource?: boolean;
    pageOptions?: PageOptions;
    hidePagination?: boolean;
    totalRows?: number;
    onTableChange?: (pageOptions: PageOptions, sortOptions: SortOptions, filters: Filters) => void;
    scroll?: {
        x?: number | true | string;
        y?: number | string;
    };

    footer?: PanelRender<T>;
    rowSelection?: TableRowSelection<T>;
    header?: string;
    className?: string;
    sticky?: boolean;
};

const Table = <T extends object>({
    columns,
    dataSource,
    rowKey,
    loading = false,
    onRowClick,
    onRowClickRequiredUseCase,
    externalDataSource = false,
    pageOptions,
    hidePagination = false,
    totalRows,
    onTableChange,
    scroll,
    footer,
    rowSelection,
    header,
    className,
    sticky,
}: Props<T>) => {
    const useCases = useSelector(usecaseSelector);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: SorterResult<T> | SorterResult<T>[]
    ) => {
        //Check for table change
        if (onTableChange) {
            let sortResult: SorterResult<T>;

            if (Array.isArray(sorter)) {
                sortResult = (sorter as SorterResult<T>[])[0];
            } else {
                sortResult = sorter as SorterResult<T>;
            }

            onTableChange(
                {
                    number: pagination.current || defaultPageOptions.number,
                    size: pagination.pageSize || defaultPageOptions.size,
                },
                {
                    column: sortResult.field ? sortResult.field.toString() : "",
                    direction: sortResult.order === "ascend" ? "asc" : "desc",
                },
                filters as Filters
            );
        }
    };

    let pagination: TablePaginationConfig | false = {
        defaultPageSize: defaultPageOptions.size,
        showSizeChanger: true,
    };

    if (externalDataSource) {
        const options = pageOptions ? pageOptions : defaultPageOptions;

        pagination = {
            ...pagination,
            total: totalRows,
            pageSize: options.size,
            defaultPageSize: options.size,
            current: options.number,
        };
    }

    if (hidePagination) pagination = false;

    return (
        <TableAD<T>
            title={header ? () => header : undefined}
            sticky={sticky}
            bordered
            scroll={scroll}
            tableLayout="fixed"
            columns={columns}
            dataSource={dataSource}
            rowKey={rowKey.toString()}
            loading={loading}
            onChange={handleTableChange}
            pagination={pagination}
            footer={footer}
            rowSelection={rowSelection}
            className={className}
            showSorterTooltip={false}
            onRow={(record, index: number) => {
                return {
                    onClick: () => {
                        if (onRowClick && hasUseCase(onRowClickRequiredUseCase, useCases))
                            onRowClick(record, index);
                    },
                };
            }}
            size="small"
        />
    );
};

export { Table };
