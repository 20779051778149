import { createSelector } from "reselect";

import { RootState } from "@/state";

import { VatRateState } from "../";

const rootSelector = (state: RootState): VatRateState => state.lookups.directory.vatRate;

export const vatRateSelector: (state: RootState) => VatRateState = createSelector(
    rootSelector,
    (root) => root
);
