import { useMemo } from "react";
import { useSelector } from "react-redux";

import { policyProductTypeItemsSelector } from "@/state/lookups/client";
import { PolicyProductType } from "@/state/lookups/client/policyProductTypes/types";

export const usePolicyProductTypes = (policyTypeIds?: string[]): PolicyProductType[] => {
    let policyProductTypes = useSelector(policyProductTypeItemsSelector);

    return useMemo(() => {
        // Filter by policy type
        if (policyTypeIds) {
            policyProductTypes = policyProductTypes.filter((p) =>
                policyTypeIds.some((id) => p.policyTypeId === id)
            );
        }

        return policyProductTypes;
    }, [policyProductTypes, policyTypeIds]);
};
