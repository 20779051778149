import { Form, Spin } from "antd";
import { FormItemProps } from "antd/lib/form";
import React, { useContext } from "react";

import { formatValue, getValidationError } from "@/app/validation";
import { ValidationResult } from "@/app/validation/types";

import { FormContext } from "./state/FormContext";

const FormItem = Form.Item;

export type FormFieldProps = FormItemProps & {
    fieldName?: string;
    value?: React.ReactNode;
    validationFieldName?: string;
    validationResults?: ValidationResult[];
    loading?: boolean;
};

const FormField: React.FC<FormFieldProps> = (props) => {
    const formData = useContext(FormContext);

    const labelCol = props.label ? formData?.labelCol : undefined;
    const wrapperCol = props.label ? formData?.wrapperCol : undefined;

    const {
        // Custom props
        fieldName,
        value,
        validationFieldName,
        validationResults,
        loading,
        // AntD Form.Item props
        ...rest
    } = props;

    const getErrorText = (): string | null => {
        const result = getValidationError(
            validationFieldName || fieldName || "",
            validationResults || []
        );

        //There is no validation error
        if (!result) return null;

        //Must be primative type
        if (
            typeof props.value !== "boolean" &&
            typeof props.value !== "number" &&
            typeof props.value !== "string" &&
            !Array.isArray(props.value) &&
            value !== undefined &&
            value !== null
        )
            return null;

        //If the value has changed then dont show message
        if (formatValue(result.attemptedValue) !== formatValue(value)) return null;

        return result.errorMessage;
    };

    const errorText = getErrorText();

    let children = props.children;

    if (loading) children = <Spin spinning={true} size="small" />;

    return (
        <FormItem
            validateStatus={errorText ? "error" : undefined}
            help={errorText}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            {...rest}
        >
            {children}
        </FormItem>
    );
};

export { FormField };
