import { Modal } from "antd";
import dayjs from "dayjs";
import React, { PropsWithChildren, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { isAuthenticatedSelector, signOut, tokenDataSelector } from "@/state/auth";

export const Secure: React.FC<PropsWithChildren> = ({ children }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const tokenData = useSelector(tokenDataSelector);

    useEffect(() => {
        if (!isAuthenticated) navigate("/auth/signin");
    }, [isAuthenticated]);

    useEffect(() => {
        let modalVisible = false;

        const checkTokenExpired = () => {
            if (!tokenData) return;

            const expiryDate = dayjs.unix(tokenData.exp);
            const hasExpired = dayjs().isAfter(expiryDate);

            if (hasExpired && !modalVisible) {
                modalVisible = true;
                dispatch(signOut());
                Modal.destroyAll();
                Modal.info({
                    title: "Session has Expired",
                    content: (
                        <div>
                            <p>Your session has expired, please click OK to sign in again</p>
                        </div>
                    ),
                    onOk: () => {
                        navigate("/auth/signin");
                    },
                });
            }
        };

        checkTokenExpired();

        const interval = setInterval(checkTokenExpired, 10000); //Every 10 secs

        return () => {
            clearInterval(interval);
        };
    }, [tokenData]);

    return <>{children}</>;
};
