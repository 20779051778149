import React from "react";
import { connect } from "react-redux";

import { RootState } from "@/state";
import { complianceRecordTypesSelector } from "@/state/compliance/lookups";
import { ComplianceRecordType } from "@/state/compliance/lookups/complianceRecordTypes/types";

type Props = {
    complianceRecordTypes: ComplianceRecordType[];
    complianceRecordTypeId: string;
};

const ComplianceRecordTypeNameComponent: React.FC<Props> = (props: Props) => {
    const { complianceRecordTypes, complianceRecordTypeId } = props;

    const complianceRecordTypeType = complianceRecordTypes.find(
        (u) => u.id === complianceRecordTypeId
    );

    if (!complianceRecordTypeType) return <span />;

    return <span>{complianceRecordTypeType.name}</span>;
};

const mapStateToProps = (state: RootState) => {
    const complianceRecordTypesState = complianceRecordTypesSelector(state);

    return {
        complianceRecordTypes: complianceRecordTypesState.items,
    };
};

const ComplianceRecordTypeName = connect(mapStateToProps)(ComplianceRecordTypeNameComponent);

export { ComplianceRecordTypeName };
