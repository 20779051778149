import { Button as ButtonAD } from "antd";
import { ButtonProps } from "antd/lib/button";
import React from "react";
import { useSelector } from "react-redux";

import { hasUseCase } from "@/app/identity";
import { IconName } from "@/app/types";
import { hasRole } from "@/config/role";
import { roleSelector, usecaseSelector } from "@/state/auth";

import { Icon } from "../common/Icon";

type Props = {
    iconName?: IconName;
    requiredUseCase?: string;
    requiredRole?: string;
    hidden?: boolean;
} & ButtonProps;

export const Button: React.FC<Props> = (props: Props) => {
    const { requiredRole, requiredUseCase, hidden, iconName, icon, ...rest } = props;

    const useCases = useSelector(usecaseSelector);
    const roles = useSelector(roleSelector);

    let visible = !hidden;

    if (requiredUseCase) visible = hasUseCase(requiredUseCase, useCases) && visible;

    if (requiredRole) visible = hasRole(requiredRole, roles) && visible;

    return (
        <>
            {visible && (
                <ButtonAD {...rest} icon={iconName ? <Icon name={props.iconName} /> : icon}>
                    {props.children}
                </ButtonAD>
            )}
        </>
    );
};
