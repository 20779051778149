import { config } from "@/config/config";

import { PageOptions, SortDirection, SortOptions } from "./types";

export const getDefaultPageOptions = (): PageOptions => {
    return {
        number: 1,
        size: config.ui.pageSize,
    };
};

export const getDefaultSortOptions = (
    column = "",
    direction: SortDirection = "asc"
): SortOptions => {
    return {
        column: column,
        direction: direction,
    };
};
