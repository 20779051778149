import { Switch as SwitchAD } from "antd";
import { SwitchChangeEventHandler, SwitchProps as SwitchPropsAD } from "antd/lib/switch";
import React, { useContext } from "react";
import { FormField, FormFieldProps } from "./FormField";
import { FormContext } from "./state/FormContext";

type SwitchProps = SwitchPropsAD;

const Switch: React.FC<SwitchProps> = (props) => {
    const formData = useContext(FormContext);
    const readonly = formData?.readonly;

    const { disabled, ...rest } = props;

    return <SwitchAD disabled={disabled || readonly} {...rest} />;
};

type FormSwitchProps = {
    input?: SwitchProps;
    field?: FormFieldProps;
    onChange?: (fieldName: string, value: boolean) => void;
};

const FormSwitch: React.FC<FormSwitchProps> = ({ onChange, input, field }) => {
    const onCheckChange: SwitchChangeEventHandler = (checked, event) => {
        if (input?.onChange) input.onChange(checked, event);
        if (onChange) onChange(field?.fieldName ?? "", checked);
    };

    return (
        <FormField {...field} valuePropName="checked">
            <Switch {...input} onChange={onCheckChange} />
        </FormField>
    );
};

export { FormSwitch, Switch };
