import { createBrowserHistory } from "history";
import { AnyAction, combineReducers, Reducer, ReducersMapObject } from "redux";
import ReduxAsyncQueue from "redux-async-queue";
import { persistStore } from "redux-persist";
import thunk from "redux-thunk";

import { config } from "@/config/config";
import { createRouterMiddleware } from "@lagunovsky/redux-react-router";
import { configureStore } from "@reduxjs/toolkit";

import apiMiddleware from "./middleware/apiMiddleware";
import createStaticReducers from "./rootReducer";

export const history = createBrowserHistory();
const routerMiddleware = createRouterMiddleware(history);

const middleware = [thunk, ReduxAsyncQueue, apiMiddleware];

const staticReducers = createStaticReducers(history);

const createReducer = (asyncReducers: ReducersMapObject) => {
    return combineReducers({
        ...staticReducers,
        ...asyncReducers,
    });
};

const store = configureStore({
    reducer: createReducer({}),
    preloadedState: undefined,
    devTools: config.environment !== "Production",
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
            .prepend(routerMiddleware)
            .concat(middleware),
});

type AsyncReducers = "directory" | "client" | "commission" | "compliance" | "invest" | "dashboard";

const createReducerManager = () => {
    const persistor = persistStore(store);

    // Add a dictionary to keep track of the registered async reducers
    const asyncReducers: ReducersMapObject = {};

    // Create an inject reducer function
    const injectReducer = (key: AsyncReducers, asyncReducer: Reducer) => {
        console.log(`injecting reducer: ${key}`);

        if (asyncReducers[key]) {
            console.log("reducer already added");
            return;
        }

        asyncReducers[key] = asyncReducer;

        store.replaceReducer(createReducer(asyncReducers));

        persistor.persist();
    };

    const hasReducer = (key: AsyncReducers): boolean => {
        return !!asyncReducers[key];
    };

    const dispatch = (action: AnyAction): void => {
        store.dispatch(action);
    };

    return {
        injectReducer,
        hasReducer,
        dispatch,
    };
};

export const reducerManager = createReducerManager();

export const getStore = () => {
    return { store };
};
