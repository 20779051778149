import { Typography } from "antd";
import React from "react";
import { FormField, FormFieldProps } from "./FormField";

type TextProps = {
    value: string | React.ReactNode;
    addon?: React.ReactNode;
    emptyValueText?: React.ReactNode;
};

const Text: React.FC<TextProps> = (props) => {
    return (
        <React.Fragment>
            <Typography.Text>{!props.value ? props.emptyValueText : props.value}</Typography.Text>
            <span style={{ float: "right" }}>{props.addon}</span>
        </React.Fragment>
    );
};

type FormTextProps = TextProps & FormFieldProps;

const FormText: React.FC<FormTextProps> = (props) => {
    const { value, addon, emptyValueText, ...formFieldProps } = props;
    return (
        <FormField {...formFieldProps} valuePropName="ignore">
            <Text value={value} addon={addon} emptyValueText={emptyValueText} />
        </FormField>
    );
};

export { FormText, Text };
