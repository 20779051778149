import { config } from "@/config/config";
import {
    isAuthenticatedSelector,
    roleSelector,
    tokenDataSelector,
} from "@/state/auth/token/selectors";
import {
    fetchAppInfo,
    fetchApplications,
    fetchUser,
    fetchUserOrganisation,
} from "@/state/context/actions";
import { isLoadingSelector } from "@/state/context/selectors";
import { fetchBranchesSimple, fetchUsersSimple } from "@/state/lookups/directory";
import { Loader } from "@/ui/controls/state/Loader";
import * as Sentry from "@sentry/react";
import { App, ConfigProvider } from "antd";
import React, { PropsWithChildren, useEffect } from "react";
import { FullStoryAPI } from "react-fullstory";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTheme } from "../hooks";

const Startup: React.FC<PropsWithChildren> = ({ children }) => {
    const location = useLocation();

    const { currentTheme, themeMap } = useTheme();

    const dispatch = useDispatch();

    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const tokenData = useSelector(tokenDataSelector);
    const roles = useSelector(roleSelector);
    const loading = useSelector(isLoadingSelector);

    useEffect(() => {
        dispatch(fetchAppInfo());
        dispatch(fetchApplications());
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchUserOrganisation());
            dispatch(fetchUser());
            dispatch(fetchUsersSimple());
            dispatch(fetchBranchesSimple());

            if (tokenData) {
                Sentry.setUser({
                    id: tokenData.nameid,
                    username: tokenData.userName,
                    email: tokenData.email,

                    displayName: `${tokenData.firstName} ${tokenData.lastName}`,
                    organisation: tokenData.organisationName,
                    branch: tokenData.branchName,
                    scope: tokenData.scope,
                    roles: roles.join(", "),
                });
                FullStoryAPI("identify", tokenData.nameid, {
                    displayName: `${tokenData.firstName} ${tokenData.lastName}`,
                    userName: tokenData.userName,
                    email: tokenData.email,
                    organisation: tokenData.organisationName,
                    branch: tokenData.branchName,
                    scope: tokenData.scope,
                    tokenExpiry: tokenData.exp,
                    roles: roles.join(", "),
                    environment: config.environment.toLowerCase(),
                });
            }
        }
    }, [isAuthenticated]);

    const isAccountPage = location.pathname === "/signin";

    if (loading && !isAccountPage)
        return <Loader text="loading application..." size="large" className="pt-16" />;

    return (
        <ConfigProvider
            theme={{
                algorithm: themeMap[currentTheme],
                token: {
                    colorPrimary: "#2597a3",
                },
            }}
        >
            <App>{children}</App>
        </ConfigProvider>
    );
};

export default Startup;
