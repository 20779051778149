import storage from "redux-persist/lib/storage";

import { Theme } from "./types";

export const getPersistStorage = () => {
    return storage;
};

export const getToken = (): string | null => {
    return localStorage.getItem("token");
};

export const setToken = (token: string | null) => {
    if (token !== null) localStorage.setItem("token", token);
    else localStorage.removeItem("token");
};

export const getVersion = (): string | null => {
    return localStorage.getItem("version");
};

export const setVersion = (version: string | null) => {
    if (version !== null) localStorage.setItem("version", version);
    else localStorage.removeItem("version");
};

export const setStoreValue = (key: string, value: string) => {
    localStorage.setItem(key, value);
};

export const getStoreValue = (key: string): string | null => {
    return localStorage.getItem(key);
};

export const getTheme = (): Theme => {
    const theme = localStorage.getItem("theme");
    if (theme === "compact") return "compact";
    if (theme === "dark") return "dark";
    return "default";
};

export const setTheme = (theme: Theme) => {
    localStorage.setItem("theme", theme);
};

export const getSideMenuCollapsed = (): boolean => {
    return localStorage.getItem("sideMenuCollapsed") === "1";
};

export const setSideMenuCollapsed = (collapsed: boolean) => {
    localStorage.setItem("sideMenuCollapsed", collapsed ? "1" : "0");
};
