import { VatRateReceiveAction } from "./actions";
import { VatRateState } from "./types";

export const defaultState: VatRateState = {
    rate: 0,
};

export const reducer = (
    state: VatRateState = defaultState,
    action: VatRateReceiveAction
): VatRateState => {
    switch (action.type) {
        case "VATRATE_LOOKUPS_RECEIVE": {
            return {
                rate: action.payload,
            };
        }
        default:
            return state;
    }
};
