import { FilterFunction } from "@/app/controls/select";
import { ValidationResult } from "@/app/validation/types";
import { Select } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { NamePath } from "antd/lib/form/interface";
import { SelectValue } from "antd/lib/select";
import React, { useContext } from "react";
import { FormText } from "./";
import { FormField } from "./FormField";
import { FormContext } from "./state/FormContext";

const Option = Select.Option;

type Props<T extends SelectValue> = {
    name?: NamePath;
    fieldName?: string;
    label?: string;
    value?: T;
    options: object[];
    optionsValue: string;
    optionsText: string;
    onOptionsText?: (value: object) => React.ReactNode;
    validationFieldName?: string;
    disabled?: boolean;
    defaultActiveFirstOption?: boolean;
    onChange?: (fieldName: string, value: T) => void;
    validationResults?: ValidationResult[];
    loading?: boolean;
    autoFocus?: boolean;
    notFoundContent?: React.ReactNode;
    placeholder?: React.ReactNode;
    showSearch?: boolean;
    filterOption?: boolean | FilterFunction;
    onSearch?: (value: string) => void;
    onSelect?: (value: T) => void;
    minWidth?: string;
    width?: string;
    hidden?: boolean;
    allowClear?: boolean;
    mode?: "multiple" | "tags";
    extra?: React.ReactNode;
    size?: SizeType;
    formFieldStyle?: React.CSSProperties;
    readonly?: boolean;
};

const FormSelect: <T extends SelectValue>(p: Props<T>) => React.ReactElement<Props<T>> | null = (
    props
) => {
    const formData = useContext(FormContext);
    const readonly = formData?.readonly || props.readonly;

    const getLabelValue = (): string => {
        const item = props.options.find((o) => o[props.optionsValue] === props.value);

        if (!item) return "";

        return item[props.optionsText];
    };

    const getOptionsText = (option: object): React.ReactNode => {
        if (props.onOptionsText) return props.onOptionsText(option);
        return option[props.optionsText];
    };

    const {
        name,
        fieldName,
        label,
        disabled = false,
        validationResults,
        defaultActiveFirstOption = true,
        loading = false,
        autoFocus = false,
        extra,
        formFieldStyle,
    } = props;

    const { value } = props;

    const labelValue = getLabelValue();

    if (readonly) return <FormText label={label} value={labelValue} />;

    if (props.hidden) return null;

    const style: React.CSSProperties = {
        minWidth: props.minWidth || "180px",
    };
    if (props.width) {
        style.minWidth = undefined;
        style.width = props.width;
    }

    return (
        <FormField
            name={name}
            label={label}
            fieldName={fieldName}
            validationResults={validationResults}
            value={value as React.ReactNode}
            loading={loading}
            validationFieldName={props.validationFieldName}
            extra={extra}
            style={formFieldStyle}
        >
            <Select
                mode={props.mode}
                showSearch={props.showSearch}
                filterOption={props.filterOption}
                onSearch={props.onSearch}
                notFoundContent={props.notFoundContent}
                placeholder={props.placeholder}
                style={style}
                value={value}
                onChange={(value) => {
                    if (props.onChange) props.onChange(props.fieldName ?? "", value);
                }}
                disabled={disabled}
                defaultActiveFirstOption={defaultActiveFirstOption}
                autoFocus={autoFocus}
                allowClear={props.allowClear}
                size={props.size}
                onSelect={(value) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    if (props.onSelect) props.onSelect(value as any);
                }}
            >
                {props.options.map((option) => (
                    <Option key={option[props.optionsValue]} value={option[props.optionsValue]}>
                        {getOptionsText(option)}
                    </Option>
                ))}
            </Select>
        </FormField>
    );
};

export { FormSelect };
