import { DATE_FORMAT } from "@/app/utils";
import { ValidationResult } from "@/app/validation/types";
import { DatePicker } from "antd";
import { NamePath } from "antd/lib/form/interface";
import dayjs, { Dayjs } from "dayjs";
import React, { useContext } from "react";
import { FormText } from "./";
import { FormField } from "./FormField";
import { FormContext } from "./state/FormContext";

type Props = {
    name?: NamePath;
    fieldName?: string;
    label: string;
    extra?: React.ReactNode;
    value?: string | number | undefined | null;
    disabled?: boolean;
    onChange?: (fieldName: string, value: string | null) => void;
    validationResults?: ValidationResult[];
    hidden?: boolean;
    allowClear?: boolean;
};

const FormDate: React.FC<Props> = (props: Props) => {
    const formData = useContext(FormContext);
    const readonly = formData?.readonly;

    const onChange = (_date: Dayjs, dateString: string) => {
        if (props.onChange) props.onChange(props.fieldName ?? "", dateString ? dateString : null);
    };

    const {
        name,
        fieldName,
        label,
        value,
        validationResults,
        disabled = false,
        extra,
        allowClear = true,
    } = props;

    if (readonly)
        return <FormText label={label} value={value ? dayjs(value).format(DATE_FORMAT) : ""} />;

    if (props.hidden) return null;

    return (
        <FormField
            name={name}
            label={label}
            fieldName={fieldName}
            validationResults={validationResults}
            value={value}
            extra={extra}
        >
            <DatePicker
                disabled={disabled}
                name={fieldName}
                value={value ? dayjs(value) : undefined}
                onChange={onChange}
                allowClear={allowClear}
            />
        </FormField>
    );
};

export { FormDate };
