import { config } from "@/config/config";

const directoryBaseApiRelative = `/api/directory`;

const directoryBaseApi = `${config.baseApi}${directoryBaseApiRelative}`;

export const identityApi = `${directoryBaseApi}/identity`;
export const usersApi = `${directoryBaseApi}/users`;
export const organisationsApi = `${directoryBaseApi}/organisations`;
export const rolesApi = `${directoryBaseApi}/roles`;
export const applicationsApi = `${directoryBaseApi}/applications`;
export const useCasesApi = `${directoryBaseApi}/usecases`;
export const branchesApi = `${directoryBaseApi}/branches`;
export const snippetsApi = `${directoryBaseApi}/snippets`;
export const auditApi = `${directoryBaseApi}/audit`;
export const changeLogsApi = `${directoryBaseApi}/changeLogs`;

export const directoryLookupsApi = `${directoryBaseApi}/lookups`;
export const allDirectoryLookupsApi = `${directoryLookupsApi}/all`;
export const companiesApi = `${directoryLookupsApi}/companies`;
export const adviceScopesApi = `${directoryLookupsApi}/adviceScopes`;
export const adviceServicesApi = `${directoryLookupsApi}/adviceServices`;
export const licenseCategoriesApi = `${directoryLookupsApi}/licenseCategories`;

export const getUserSignatureApi = (userId: string, relative = false): string =>
    `${relative ? directoryBaseApiRelative : directoryBaseApi}/users/${userId}/config/signature`;

export const getOrganisationLogoApi = (organisationId: string, relative = false): string =>
    `${
        relative ? directoryBaseApiRelative : directoryBaseApi
    }/organisations/${organisationId}/config/logo`;
