import { useClipboard as useClipboardHook } from "use-clipboard-copy";
import { showMessage } from "../feedback/notifcation";

export const useClipboard = () => {
    return useClipboardHook({
        onSuccess() {
            showMessage("info", "Copied to clipboard");
        },
    });
};
