import { createSelector } from "reselect";

import { RootState } from "@/state";

import { DeactivationReason, DeactivationReasonListState } from "../types";

const rootSelector = (state: RootState): DeactivationReasonListState =>
    state.lookups.client.deactivationReasons.list;

export const deactivationReasonsSelector: (state: RootState) => DeactivationReasonListState =
    createSelector(rootSelector, (root) => root);

export const deactivationReasonsItemsSelector: (state: RootState) => DeactivationReason[] =
    createSelector(deactivationReasonsSelector, (root) => root.items);
