import { useEffect, useMemo, useState } from "react";

import { currentThemeSelector, receiveCurrentTheme, ThemeName } from "@/state/context";
import { getTheme, setTheme } from "@/state/storage";
import { theme } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useBreakpoint } from "./useBreakpoint";

type ThemeOption = {
    label: string;
    value: ThemeName;
};

type ThemeMap = Record<ThemeName, typeof theme.defaultAlgorithm>;

type UseTheme = {
    currentTheme: ThemeName;
    themeOptions: ThemeOption[];
    themeMap: ThemeMap;
    setCurrentTheme: (theme: ThemeName) => void;
    navigatorHeight: number;
};

export const useTheme = (): UseTheme => {
    const dispatch = useDispatch();
    const { isAtLeast, breakpoint } = useBreakpoint();

    const currentTheme = useSelector(currentThemeSelector);

    const [navigatorHeight, setNavigatorHeight] = useState<number>(0);

    const themeOptions = useMemo(() => {
        const options: ThemeOption[] = [];

        if (isAtLeast("xl"))
            options.push(
                {
                    label: "Default",
                    value: "default",
                },
                {
                    label: "Dark",
                    value: "dark",
                }
            );

        options.push({
            label: "Compact",
            value: "compact",
        });
        return options;
    }, [breakpoint]);

    const themeMap = useMemo(() => {
        return {
            default: theme.defaultAlgorithm,
            dark: theme.darkAlgorithm,
            compact: theme.compactAlgorithm,
        };
    }, []);

    useEffect(() => {
        //  current theme stored client side
        let theme = getTheme();
        if (!isAtLeast("xl")) theme = "compact";
        dispatch(receiveCurrentTheme(theme));
    }, []);

    useEffect(() => {
        setNavigatorHeight(currentTheme === "compact" ? 61 : 69);
    }, [currentTheme]);

    return {
        themeOptions,
        themeMap,
        setCurrentTheme: (theme: ThemeName) => {
            setTheme(theme);
            dispatch(receiveCurrentTheme(theme));
        },
        currentTheme,
        navigatorHeight,
    };
};
