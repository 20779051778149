import { History } from "history";

import { createRouterReducer } from "@lagunovsky/redux-react-router";

import { reducer as auth } from "./auth/reducer";
import { reducer as context } from "./context/reducer";
import { reducer as lookups } from "./lookups/reducer";

const createStaticReducers = (history: History) => {
    return {
        context: context,
        auth: auth,
        lookups: lookups,
        router: createRouterReducer(history),
    };
};

export default createStaticReducers;
