import { Dispatch } from "redux";

import { ApiAction } from "@/app/types";
import { allComplianceLookupsApi } from "@/config/api/compliance";

import { Lookups, receiveComplianceRecordTypes } from "../";

type LookupsReceiveAction = {
    type: "COMPLIANCE_LOOKUPS_RECEIVE";
};
type LookupsFetchingAction = { type: "COMPLIANCE_LOOKUPS_FETCHING" };
type LookupsFetchingErrorAction = { type: "COMPLIANCE_LOOKUPS_FETCHING_ERROR" };

export type LookupsAction =
    | LookupsReceiveAction
    | LookupsFetchingAction
    | LookupsFetchingErrorAction;

export const fetchAllComplianceLookups = (): ApiAction => ({
    type: "API",
    endpoint: allComplianceLookupsApi,
    onSuccess: (payload: Lookups, dispatch: Dispatch) => {
        dispatch(receiveComplianceRecordTypes(payload.complianceRecordTypes));
    },
    dispatchPrefix: "COMPLIANCE_LOOKUPS",
});
