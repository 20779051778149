import { useSelector } from "react-redux";

import { hasUseCase as hasUseCaseInternal } from "@/app/identity";
import { usecaseSelector } from "@/state/auth";

export const useUseCase = () => {
    const useCases = useSelector(usecaseSelector);

    return {
        hasUseCase: (useCase) => {
            return hasUseCaseInternal(useCase, useCases);
        },
    };
};
