import React, { Suspense } from "react";
import { Route, Routes as RRoutes } from "react-router-dom";
import Layout from "../layout/Layout";
import { Secure } from "../layout/Secure";
import { Activate, ResetPassword, ResetPasswordRequest, SignIn } from "./account";
import {
    CancellationRegisterReport,
    ClientDashboard,
    ClientExport,
    ClientImport,
    ClientList,
    ClientPreview,
    NewBusinessRegisterReport,
    PolicyList,
    PolicyProductList,
    PolicyProductTypeList,
    PolicyTypeCharacteristicList,
} from "./client";
import {
    BankStatementRecordList,
    ClientRevenueReport,
    CommissionLapseReport,
    CommissionList,
    CommissionStatementTemplateList,
    CommissionTypeList,
    ProjectionsReport,
    SplitRulePolicyList,
    StatementList,
    StatementPreview,
    UserMonthlyCommissionReport,
} from "./commission";
import {
    AcknowledgeRoaInvest,
    AcknowledgeRoaLifePersonal,
    ComplianceRecords,
    DisclosureLetter,
    RiskMatrix,
    RoaILifePersonal,
    RoaInvest,
    ServiceLevelAgreement,
} from "./compliance";
import {
    AdviceScopeList,
    AdviceServiceList,
    AuditLogList,
    ChangeLogList,
    CompanyList,
    LicenseCategoryList,
    OrganisationList,
    RoleList,
    UserList,
} from "./directory";
import { SuspenseLoader } from "./SuspenseLoader";

const Routes: React.FC = () => (
    <Suspense fallback={<SuspenseLoader />}>
        <RRoutes>
            <Route path="/auth/*" element={<AuthRoutes />} />
            <Route
                path="/compliance/records/:complianceRecordId/acknowledge/*"
                element={<ComplianceRecordAcknowledgeRoutes />}
            />
            <Route path="*" element={<BaseRoutes />} />
        </RRoutes>
    </Suspense>
);

const AuthRoutes: React.FC = () => {
    return (
        <RRoutes>
            {/* ACCOUNT ----------------------------------------------------------------------------- */}
            <Route path="/signin" element={<SignIn />} />
            <Route path="/admin" element={<SignIn />} />
            <Route path="/activate" element={<Activate />} />
            <Route path="/resetPasswordRequest" element={<ResetPasswordRequest />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            {/* ------------------------------------------------------------------------------------- */}
        </RRoutes>
    );
};

const BaseRoutes: React.FC = () => {
    return (
        <Secure>
            <Layout>
                <RRoutes>
                    {/* DASHBOARD --------------------------------------------------------------------------- */}
                    {/* <Route  path="/" element={<Dashboard />} />
                        <Route  path="/client/dashboard" element={<Dashboard />} /> */}
                    {/* ------------------------------------------------------------------------------------- */}
                    {/* CLIENT ------------------------------------------------------------------------------- */}
                    <Route path="/" element={<ClientDashboard />} />
                    <Route path="/client" element={<ClientDashboard />} />
                    <Route path="/client/dashboard" element={<ClientDashboard />} />
                    <Route path="/client/clients" element={<ClientList />} />
                    <Route path="/client/policies" element={<PolicyList />} />
                    <Route path="/client/import" element={<ClientImport />} />
                    <Route path="/client/export" element={<ClientExport />} />
                    <Route path="/client/clients/:clientId" element={<ClientPreview />} />
                    <Route
                        path="/client/lookups/policyProductTypes"
                        element={<PolicyProductTypeList />}
                    />
                    <Route path="/client/lookups/policyProducts" element={<PolicyProductList />} />
                    <Route
                        path="/client/lookups/policyTypeCharacteristics"
                        element={<PolicyTypeCharacteristicList />}
                    />
                    <Route
                        path="/client/reports/newBusinessRegister"
                        element={<NewBusinessRegisterReport />}
                    />
                    <Route
                        path="/client/reports/cancellationRegister"
                        element={<CancellationRegisterReport />}
                    />
                    {/* ------------------------------------------------------------------------------------- */}
                    {/* COMMISSION -------------------------------------------------------------------------- */}
                    <Route path="/commission" element={<StatementList />} />
                    <Route path="/commission/statements" element={<StatementList />} />
                    <Route
                        path="/commission/statements/:commissionStatementId"
                        element={<StatementPreview />}
                    />
                    <Route
                        path="/commission/templates"
                        element={<CommissionStatementTemplateList />}
                    />
                    <Route path="/commission/commissionEntries" element={<CommissionList />} />
                    <Route
                        path="/commission/commissionSplitRulePolicies"
                        element={<SplitRulePolicyList />}
                    />
                    <Route
                        path="/commission/bankStatement/records"
                        element={<BankStatementRecordList />}
                    />
                    <Route
                        path="/commission/reports/revenueClient"
                        element={<ClientRevenueReport />}
                    />
                    <Route
                        path="/commission/reports/userMonthlyCommission"
                        element={<UserMonthlyCommissionReport />}
                    />
                    <Route path="/commission/reports/projections" element={<ProjectionsReport />} />
                    <Route
                        path="/commission/reports/commissionLapse"
                        element={<CommissionLapseReport />}
                    />
                    <Route path="/commission/lookups/commTypes" element={<CommissionTypeList />} />
                    {/* ------------------------------------------------------------------------------------- */}
                    {/* DIRECTORY --------------------------------------------------------------------------- */}
                    <Route path="/directory" element={<UserList />} />
                    <Route path="/directory/users" element={<UserList />} />
                    <Route path="/directory/organisations" element={<OrganisationList />} />
                    <Route path="/directory/roles" element={<RoleList />} />
                    <Route path="/directory/lookups/companies" element={<CompanyList />} />
                    <Route path="/directory/lookups/adviceScopes" element={<AdviceScopeList />} />
                    <Route
                        path="/directory/lookups/adviceServices"
                        element={<AdviceServiceList />}
                    />
                    <Route
                        path="/directory/lookups/licenseCategories"
                        element={<LicenseCategoryList />}
                    />
                    <Route path="/directory/logs/changeLogs" element={<ChangeLogList />} />
                    <Route path="/directory/logs/auditLogs" element={<AuditLogList />} />
                    {/* ------------------------------------------------------------------------------------- */}
                    {/* COMPLIANCE -------------------------------------------------------------------------- */}
                    <Route path="/compliance" element={<RiskMatrix />} />
                    <Route path="/compliance/onboarding/riskMatrix" element={<RiskMatrix />} />
                    <Route
                        path="/compliance/onboarding/riskMatrix/:complianceRecordId"
                        element={<RiskMatrix />}
                    />
                    <Route path="/compliance/roa/invest" element={<RoaInvest />} />
                    <Route
                        path="/compliance/roa/invest/:complianceRecordId"
                        element={<RoaInvest />}
                    />
                    <Route path="/compliance/roa/lifePersonal" element={<RoaILifePersonal />} />
                    <Route
                        path="/compliance/roa/lifePersonal/:complianceRecordId"
                        element={<RoaILifePersonal />}
                    />
                    <Route path="/compliance/records" element={<ComplianceRecords />} />
                    <Route
                        path="/compliance/onboarding/serviceLevelAgreement"
                        element={<ServiceLevelAgreement />}
                    />
                    <Route
                        path="/compliance/onboarding/serviceLevelAgreement/:complianceRecordId"
                        element={<ServiceLevelAgreement />}
                    />
                    <Route
                        path="/compliance/onboarding/disclosureLetter"
                        element={<DisclosureLetter />}
                    />
                    {/* ------------------------------------------------------------------------------------- */}
                </RRoutes>
            </Layout>
        </Secure>
    );
};

const ComplianceRecordAcknowledgeRoutes: React.FC = () => {
    return (
        <RRoutes>
            <Route path="/roa/invest" element={<AcknowledgeRoaInvest />} />
            <Route path="/roa/lifePersonal" element={<AcknowledgeRoaLifePersonal />} />
        </RRoutes>
    );
};

export default Routes;
