import { Input } from "antd";
import { NamePath } from "antd/lib/form/interface";
import React, { useContext } from "react";

import { ValidationResult } from "@/app/validation/types";

import { FormField } from "./FormField";
import { FormContext } from "./state/FormContext";

const { TextArea } = Input;

type Props = {
    name?: NamePath;
    fieldName?: string;
    label?: string;
    value?: string;
    disabled?: boolean;
    onChange?: (fieldName: string, value: string) => void;
    validationResults?: ValidationResult[];
    autoFocus?: boolean;
    validationFieldName?: string;
    hidden?: boolean;
    rows?: number;
    extra?: React.ReactNode;
};

const FormTextArea: React.FC<Props> = (props: Props) => {
    const formData = useContext(FormContext);
    const readonly = formData?.readonly;

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (props.onChange) props.onChange(props.fieldName ?? "", event.target.value);
    };

    const { name, fieldName, label = "", value, validationResults, disabled = false, rows } = props;

    if (props.hidden) return null;

    return (
        <FormField
            name={name}
            label={label}
            fieldName={fieldName}
            validationResults={validationResults}
            value={value}
            validationFieldName={props.validationFieldName}
            extra={props.extra}
        >
            <TextArea
                autoFocus={props.autoFocus}
                disabled={disabled || readonly}
                name={fieldName}
                id={fieldName}
                value={value}
                onChange={onChange}
                rows={rows}
            />
        </FormField>
    );
};

export { FormTextArea };
