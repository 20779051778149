import { useMemo } from "react";
import { useSelector } from "react-redux";

import { userCompanyIdsSelector, userCompanyPolicyTypeIdsSelector } from "@/state/context";
import { Company, companiesItemsSelector } from "@/state/lookups/directory";

export const useUserCompanies = (policyTypeIds?: string[]): Company[] => {
    const userCompanyIds = useSelector(userCompanyIdsSelector);
    const userCompanyPolicyTypeIds = useSelector(userCompanyPolicyTypeIdsSelector);
    const companies = useSelector(companiesItemsSelector);

    return useMemo(() => {
        // Filter by user companies
        let userCompanies = companies.filter((c) => userCompanyIds.some((id) => c.id === id));

        // Filter by policy type
        if (policyTypeIds) {
            userCompanies = userCompanies.filter(
                (c) =>
                    userCompanyPolicyTypeIds[c.id]?.some((policyTypeId) =>
                        policyTypeIds.some((id) => policyTypeId === id)
                    )
            );
        }

        return userCompanies;
    }, [userCompanyIds, userCompanyPolicyTypeIds, companies, policyTypeIds]);
};
