export const splitCamelCase = (text: string) => {
    text = text.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1");
    return text.charAt(0).toUpperCase() + text.slice(1);
};

type Properties = {
    [key in string]: string;
};

//Source: https://github.com/Matt-Esch/string-template/blob/master/index.js
export const format = (text: string, properties: Properties) => {
    const regexp = /\{([0-9a-zA-Z_]+)\}/g;

    return text.replace(regexp, (match, property, index) => {
        let result: string | null;

        if (text[index - 1] === "{" && text[index + match.length] === "}") {
            return property;
        } else {
            result = properties.hasOwnProperty(property) ? properties[property] : null;
            if (result === null || result === undefined) {
                return "{" + match + "}";
            }
            return result;
        }
    });
};

// make a list in the Oxford comma style (eg "a, b, c, and d")
// Examples with conjunction "and":
// ["a"] -> "a"
// ["a", "b"] -> "a and b"
// ["a", "b", "c"] -> "a, b, and c"
export const oxfordJoin = function (arr: string[], separator = ", ", conjunction = "and") {
    return arr.join(separator).replace(/, ([^,]*)$/, ` ${conjunction} $1`);
};

export const capitaliseFirstLetter = (text: string | undefined): string => {
    text = text?.trim();
    if (!text) return "";
    text = text.toLowerCase();
    const arrOfWords = text.split(" ");
    const arrOfWordsCased: string[] = [];
    for (let i = 0; i < arrOfWords.length; i++) {
        const char = arrOfWords[i].split("");
        char[0] = char[0].toUpperCase();
        arrOfWordsCased.push(char.join(""));
    }
    return arrOfWordsCased.join(" ");
};

export const firstLetterUpper = (text: string | undefined): string => {
    if (!text) return "";
    if (text.length === 0) return "";
    return text[0].toUpperCase();
};

export const eachFirstLetterUpper = (text: string | undefined): string => {
    if (!text) return "";
    if (text.length === 0) return "";
    return text
        .split(" ")
        .map((w) => firstLetterUpper(w))
        .join("");
};
