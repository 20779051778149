import { useMemo } from "react";
import { useSelector } from "react-redux";

import { hasUseCase } from "@/app/identity";
import { usecaseSelector } from "@/state/auth";

export const useHasUseCase = (useCase: string): boolean => {
    const useCases = useSelector(usecaseSelector);
    return useMemo(() => hasUseCase(useCase, useCases), [useCase, useCases]);
};
