// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.\\!visible {
    visibility: visible !important
}
.visible {
    visibility: visible !important
}
.fixed {
    position: fixed !important
}
.absolute {
    position: absolute !important
}
.sticky {
    position: sticky !important
}
.float-right {
    float: right !important
}
.mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important
}
.my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
}
.mb-0 {
    margin-bottom: 0px !important
}
.mb-12 {
    margin-bottom: 3rem !important
}
.mb-4 {
    margin-bottom: 1rem !important
}
.mb-8 {
    margin-bottom: 2rem !important
}
.ml-4 {
    margin-left: 1rem !important
}
.mr-0 {
    margin-right: 0px !important
}
.mr-2 {
    margin-right: 0.5rem !important
}
.mr-4 {
    margin-right: 1rem !important
}
.mt-12 {
    margin-top: 3rem !important
}
.mt-2 {
    margin-top: 0.5rem !important
}
.mt-20 {
    margin-top: 5rem !important
}
.mt-4 {
    margin-top: 1rem !important
}
.mt-8 {
    margin-top: 2rem !important
}
.block {
    display: block !important
}
.inline {
    display: inline !important
}
.flex {
    display: flex !important
}
.table {
    display: table !important
}
.grid {
    display: grid !important
}
.\\!hidden {
    display: none !important
}
.hidden {
    display: none !important
}
.h-full {
    height: 100% !important
}
.w-2\\/5 {
    width: 40% !important
}
.w-full {
    width: 100% !important
}
.flex-grow {
    flex-grow: 1 !important
}
.cursor-move {
    cursor: move !important
}
.cursor-pointer {
    cursor: pointer !important
}
.resize {
    resize: both !important
}
.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important
}
.justify-end {
    justify-content: flex-end !important
}
.justify-center {
    justify-content: center !important
}
.gap-4 {
    gap: 1rem !important
}
.overflow-hidden {
    overflow: hidden !important
}
.overflow-y-auto {
    overflow-y: auto !important
}
.overflow-x-hidden {
    overflow-x: hidden !important
}
.p-8 {
    padding: 2rem !important
}
.px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important
}
.px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important
}
.pb-12 {
    padding-bottom: 3rem !important
}
.pb-2 {
    padding-bottom: 0.5rem !important
}
.pb-3 {
    padding-bottom: 0.75rem !important
}
.pb-8 {
    padding-bottom: 2rem !important
}
.pl-2 {
    padding-left: 0.5rem !important
}
.pt-0 {
    padding-top: 0px !important
}
.pt-12 {
    padding-top: 3rem !important
}
.pt-16 {
    padding-top: 4rem !important
}
.pt-4 {
    padding-top: 1rem !important
}
.pt-6 {
    padding-top: 1.5rem !important
}
.text-center {
    text-align: center !important
}
.font-bold {
    font-weight: 700 !important
}
.font-normal {
    font-weight: 400 !important
}
.underline {
    text-decoration-line: underline !important
}
.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important
}
.hover\\:underline:hover {
    text-decoration-line: underline !important
}
@media (min-width: 1194px) {
    .lg\\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important
    }
}
@media (min-width: 1440px) {
    .xl\\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr)) !important
    }
}
`, "",{"version":3,"sources":["webpack://./src/ui/styles/css/tailwind.css"],"names":[],"mappings":"AAAA;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA,4BAAU;IAAV;AAAU;AAAV;IAAA,2BAAU;IAAV;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA,6BAAU;IAAV;AAAU;AAAV;IAAA,gCAAU;IAAV;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AAAU;AAAV;IAAA;AACA;AADA;IAAA;QAAA;IACA;AAAA;AADA;IAAA;QAAA;IACA;AAAA","sourcesContent":["@tailwind utilities;\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
