import { lazy } from "react";

import { reducerManager } from "@/state/configureStore";

import { loadClientLookups } from "./client";
import { loadDirectoryLookups } from "./directory";

const ensureComplianceReducer = async (loadLookups = true) => {
    if (reducerManager.hasReducer("compliance")) return;

    const reducer = await import("@/state/compliance/reducer").then(
        (reducerModule) => reducerModule.reducer
    );
    reducerManager.injectReducer("compliance", reducer);

    if (loadLookups) {
        //Load lookups
        loadClientLookups();
        loadDirectoryLookups();

        const fetchAllComplianceLookups = await import(
            "@/state/compliance/lookups/all/actions"
        ).then((actionsModule) => actionsModule.fetchAllComplianceLookups);
        reducerManager.dispatch(fetchAllComplianceLookups());
    }
};

export const RiskMatrix = lazy(() =>
    import("@/ui/app/compliance/onboarding/riskMatrix/RiskMatrix").then(async (module) => {
        await ensureComplianceReducer();
        return module;
    })
);

export const RoaInvest = lazy(() =>
    import("@/ui/app/compliance/roa/invest/RoaInvest").then(async (module) => {
        await ensureComplianceReducer();
        return module;
    })
);

export const RoaILifePersonal = lazy(() =>
    import("@/ui/app/compliance/roa/lifePersonal/RoaLifePersonal").then(async (module) => {
        await ensureComplianceReducer();
        return module;
    })
);

export const ComplianceRecords = lazy(() =>
    import("@/ui/app/compliance/records/list/ComplianceRecordList").then(async (module) => {
        await ensureComplianceReducer();
        return module;
    })
);

export const ServiceLevelAgreement = lazy(() =>
    import("@/ui/app/compliance/onboarding/serviceLevelAgreement/ServiceLevelAgreement").then(
        async (module) => {
            await ensureComplianceReducer();
            return module;
        }
    )
);

export const DisclosureLetter = lazy(() =>
    import("@/ui/app/compliance/onboarding/disclosureLetter/DisclosureLetter").then(
        async (module) => {
            await ensureComplianceReducer();
            return module;
        }
    )
);

export const AcknowledgeRoaInvest = lazy(() =>
    import("@/ui/app/compliance/records/acknowledge/roa/AcknowledgeRoaInvest").then(
        async (module) => {
            await ensureComplianceReducer(false);
            return module;
        }
    )
);

export const AcknowledgeRoaLifePersonal = lazy(() =>
    import("@/ui/app/compliance/records/acknowledge/roa/AcknowledgeRoaLifePersonal").then(
        async (module) => {
            await ensureComplianceReducer(false);
            return module;
        }
    )
);
