import { Layout, Menu as MenuAD, MenuProps, theme } from "antd";
import React, { CSSProperties, useState } from "react";
// import { useThemeSwitcher } from "react-css-theme-switcher";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { hasPermissionsMenuGroup, hasRoles, hasUseCases } from "@/app/identity";
import { defaultOpenGroupNames } from "@/config/menu";
import { RootState } from "@/state";
import { roleSelector, usecaseSelector } from "@/state/auth";
import { currentMenuSelector, currentRootNavigationItemSelector } from "@/state/context/selectors";
import { Menu, MenuLink, RootNavigationItem } from "@/state/context/types";
import { getSideMenuCollapsed, setSideMenuCollapsed } from "@/state/storage";
import { Icon } from "@/ui/controls/common/Icon";
import { useTheme } from "../hooks";

const { Sider } = Layout;

type Props = {
    menu: Menu;
    rootNavigationItem: RootNavigationItem;
    useCases: string[];
    roles: string[];
};

const SideMenu: React.FC<Props> = (props) => {
    const { currentTheme } = useTheme();
    const [collapsed, setCollapsed] = useState<boolean>(getSideMenuCollapsed());

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const getMenuItemStyle = (link: MenuLink, item: RootNavigationItem): CSSProperties => {
        if (!link.isCurrent) return {};
        return {
            backgroundColor: `${item.color}`,
        };
    };

    const menuItems: MenuProps["items"] = props.menu.groups
        .filter((group) => hasPermissionsMenuGroup(group, props.useCases, props.roles))
        .map((group) => {
            return {
                // type: "group",
                key: group.name,
                label: (
                    <span>
                        <Icon name={group.icon} />
                        <span>{group.name}</span>
                    </span>
                ),
                children: group.links
                    .filter(
                        (link) =>
                            hasUseCases(link.useCases, props.useCases) &&
                            hasRoles(link.roles, props.roles)
                    )
                    .map((link) => {
                        return {
                            key: link.relativePath,
                            style: getMenuItemStyle(link, props.rootNavigationItem),
                            label: (
                                <Link to={`${props.menu.relativePath}${link.relativePath}`}>
                                    <span>{link.name}</span>
                                </Link>
                            ),
                        };
                    }),
            };
        });

    const background = currentTheme === "dark" ? colorBgContainer : undefined;
    const menuTheme = currentTheme === "dark" ? undefined : "dark";

    return (
        <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={(c) => {
                setCollapsed(c);
                setSideMenuCollapsed(c);
            }}
            width={225}
            className={currentTheme}
            style={{ background: background }}
        >
            <MenuAD
                theme={menuTheme}
                mode="inline"
                defaultOpenKeys={collapsed ? [] : defaultOpenGroupNames()}
                selectedKeys={[]}
                items={menuItems}
                style={{ background: background }}
            />
        </Sider>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        menu: currentMenuSelector(state),
        rootNavigationItem: currentRootNavigationItemSelector(state),
        useCases: usecaseSelector(state),
        roles: roleSelector(state),
    };
};

export default connect(mapStateToProps)(SideMenu);
