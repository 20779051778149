import { findIndex } from "lodash";
import { useCallback, useContext } from "react";

import { ViewportContext } from "../contexts/ViewportContext";

export type Breakpoint = "0" | "sm" | "md" | "lg" | "xl";

type IsAtBreakpointFunction = (breakpoint: Breakpoint) => boolean;

const breakpoints: Breakpoint[] = ["0", "sm", "md", "lg", "xl"];

type BreakpointReturn = {
    isAtLeast: IsAtBreakpointFunction;
    breakpoint: Breakpoint;
};

export const useBreakpoint = (): BreakpointReturn => {
    const { width } = useContext(ViewportContext);
    const breakpoint = getBreakpoint(width);
    const isAtLeast: IsAtBreakpointFunction = useCallback(
        (b: Breakpoint): boolean => {
            const current = findIndex(breakpoints, (bp) => bp === breakpoint);
            const index = findIndex(breakpoints, (bp) => bp === b);
            return current >= index;
        },
        [breakpoint]
    );

    return { isAtLeast, breakpoint };
};

// Currently these widths are repeated here
// src/oneadvisor/web/tailwind.config.js
function getBreakpoint(width: number) {
    if (width >= 1440) {
        return "xl";
    }
    if (width >= 1194) {
        return "lg";
    }
    if (width >= 768) {
        return "md";
    }
    if (width >= 414) {
        return "sm";
    }
    return "0";
}
