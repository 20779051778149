import { createSelector } from "reselect";

import { RootState } from "@/state";

import { PolicyNewBusinessTypesListState } from "../types";

const rootSelector = (state: RootState): PolicyNewBusinessTypesListState =>
    state.lookups.client.policyNewBusinessTypes.list;

export const policyNewBusinessTypesSelector: (state: RootState) => PolicyNewBusinessTypesListState =
    createSelector(rootSelector, (root) => root);
