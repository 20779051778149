import { PolicyNewBusinessType } from "../types";

type PolicyNewBusinessTypeListReceiveAction = {
    type: "POLICYNEWBUSINESSTYPES_LIST_RECEIVE";
    payload: PolicyNewBusinessType[];
};

export type PolicyNewBusinessTypeListAction = PolicyNewBusinessTypeListReceiveAction;

export const receivePolicyNewBusinessTypes = (
    payload: PolicyNewBusinessType[]
): PolicyNewBusinessTypeListAction => ({
    type: "POLICYNEWBUSINESSTYPES_LIST_RECEIVE",
    payload,
});
