import { Layout as LayoutAD } from "antd";
import React, { ReactNode } from "react";
import { useTheme } from "../hooks";
import { useScrollToTop } from "../hooks/useScrollToTop";
import Navigator from "./Navigator";
import SideMenu from "./SideMenu";

const { Content } = LayoutAD;

type Props = {
    children: ReactNode;
};

const Layout: React.FC<Props> = (props: Props) => {
    useScrollToTop();

    const { navigatorHeight } = useTheme();

    return (
        <LayoutAD>
            <Navigator />
            <Content
                style={{
                    marginTop: navigatorHeight,
                    height: `calc(100vh - ${navigatorHeight}px)`,
                }}
            >
                <LayoutAD>
                    <SideMenu />
                    <LayoutAD>
                        <Content style={{ margin: "0px 18px 0", overflow: "initial" }}>
                            {props.children}
                        </Content>
                    </LayoutAD>
                </LayoutAD>
            </Content>
        </LayoutAD>
    );
};

export default Layout;
