import { DeactivationReason } from "../types";

type DeactivationReasonListReceiveAction = {
    type: "DEACTIVATIONREASON_LIST_RECEIVE";
    payload: DeactivationReason[];
};

export type DeactivationReasonListAction = DeactivationReasonListReceiveAction;

export const receiveDeactivationReasons = (
    payload: DeactivationReason[]
): DeactivationReasonListAction => ({
    type: "DEACTIVATIONREASON_LIST_RECEIVE",
    payload,
});
