import { Col, Row, Spin } from "antd";
import { SpinSize } from "antd/lib/spin";
import React from "react";

type Props = {
    text?: string;
    size?: SpinSize;
    className?: string;
};

const Loader: React.FC<Props> = (props: Props) => (
    <Row style={{ height: "100%", alignItems: "center" }} className={props.className}>
        <Col xs={24}>
            <Row justify="center">
                <Col>
                    <Spin size={props.size} spinning={true} />
                </Col>
            </Row>
            {props.text && (
                <Row justify="center" className="mt-4 mb-4">
                    <Col>{props.text}</Col>
                </Row>
            )}
        </Col>
    </Row>
);

export { Loader };
