import React from "react";
import { connect } from "react-redux";

import { RootState } from "@/state";
import { deactivationReasonsSelector } from "@/state/lookups/client";
import { DeactivationReason } from "@/state/lookups/client/deactivationReasons/types";

type Props = {
    deactivationReasons: DeactivationReason[];
    deactivationReasonId: string;
};

const DeactivationReasonNameComponent: React.FC<Props> = (props: Props) => {
    const { deactivationReasons, deactivationReasonId } = props;

    const deactivationReason = deactivationReasons.find((u) => u.id === deactivationReasonId);

    if (!deactivationReason) return <span />;

    return <span>{deactivationReason.reason}</span>;
};

const mapStateToProps = (state: RootState) => {
    const deactivationReasonState = deactivationReasonsSelector(state);

    return {
        deactivationReasons: deactivationReasonState.items,
    };
};

const DeactivationReasonName = connect(mapStateToProps)(DeactivationReasonNameComponent);

export { DeactivationReasonName };
