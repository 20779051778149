import { hasRole, hasUseCase } from "@/app/identity";
import { RootState } from "@/state";
import { roleSelector, usecaseSelector } from "@/state/auth";
import { List, Switch } from "antd";
import React from "react";
import { connect } from "react-redux";

type Props<T, K> = {
    header?: string;
    disableConditional?: (item: T) => boolean;
    headerTextStyle?: React.CSSProperties;
    dataSource: T[];
    selectedIds: K[];
    itemName: (item: T) => React.ReactNode;
    idKey: keyof T;
    editUseCase?: string;
    editRole?: string;
    onChange: (ids: K[]) => void;
    disableToggleAll?: boolean;
} & PropsFromState;

function FormSwitchList<T, K>(props: Props<T, K>) {
    const isItemSelected = (item: T) => {
        return props.selectedIds?.some((id) => id === (item[props.idKey] as unknown));
    };

    const toggle = (item: T) => {
        let itemsModified = [...props.selectedIds];

        if (isItemSelected(item)) {
            itemsModified = props.selectedIds.filter((id) => id !== (item[props.idKey] as unknown));
        } else {
            itemsModified.push(item[props.idKey] as K);
        }

        props.onChange(itemsModified);
    };

    const toggleAll = (checked: boolean) => {
        if (!checked) props.onChange([]);
        else {
            props.onChange(props.dataSource.map((d) => d[props.idKey] as K));
        }
    };

    const editable = (item?: T) => {
        let isEditable = true;

        if (props.disableConditional && item) isEditable = props.disableConditional(item);

        if (props.editUseCase) {
            isEditable = hasUseCase(props.editUseCase, props.useCases);
            return isEditable;
        }

        if (props.editRole) {
            isEditable = hasRole(props.editRole, props.roles);
            return isEditable;
        }

        return isEditable;
    };

    const getHeader = () => {
        if (!props.header && props.disableToggleAll) return undefined;

        return (
            <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                    <h4 className="mb-0" style={props.headerTextStyle}>
                        {props.header}
                    </h4>
                </div>
                {!props.disableToggleAll && editable() && (
                    <div style={{ paddingRight: 8 }}>
                        <span className="mr-4">Toggle All </span>
                        <Switch onChange={(checked) => toggleAll(checked)} size="small" />
                    </div>
                )}
            </div>
        );
    };

    return (
        <List
            header={getHeader()}
            bordered={true}
            size="small"
            dataSource={props.dataSource}
            renderItem={(item: T) => (
                <List.Item
                    actions={[
                        <Switch
                            key="1"
                            disabled={!editable(item)}
                            checked={isItemSelected(item)}
                            onChange={() => toggle(item)}
                            size="small"
                        />,
                    ]}
                >
                    {props.itemName(item)}
                </List.Item>
            )}
            className="mb-8"
        />
    );
}

type PropsFromState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: RootState) => {
    return {
        roles: roleSelector(state),
        useCases: usecaseSelector(state),
    };
};

function getFormSwitchList<T, K>() {
    return connect(mapStateToProps)(
        FormSwitchList as (props: Props<T, K>) => React.ReactElement<Props<T, K>>
    );
}

export { getFormSwitchList };
