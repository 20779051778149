import { Layout, Menu, MenuProps, Popover, Tag, theme } from "antd";
import React, { CSSProperties } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { hasPermissionsMenuGroups } from "@/app/identity";
import { config } from "@/config/config";
import logoLight from "@/images/logo-light.svg";
import { RootState } from "@/state";
import { roleSelector, tokenDataSelector, usecaseSelector } from "@/state/auth";
import {
    contextSelector,
    currentRootNavigationItemSelector,
    menusSelector,
    rootNavigationItemsSelector,
} from "@/state/context/selectors";
import { RootNavigationItem } from "@/state/context/types";

import { Icon } from "../controls/common/Icon";
import { IdentityStatus } from "../controls/common/IdentityStatus";
import { useTheme } from "../hooks";
import { Settings } from "./Settings";

const { Header } = Layout;

const appNameStyle: CSSProperties = {
    fontSize: "20px",
    width: "175px",
    float: "left",
    color: "#ffffff",
};

const settingsStyle: CSSProperties = {
    display: "flex",
    alignItems: "center",
    height: "100%",
    float: "right",
    color: "#ffffff",
    zIndex: 12,
    cursor: "pointer",
    paddingLeft: 32,
};

const profileStyle: CSSProperties = {
    paddingTop: "5px",
    fontSize: "14px",
    float: "right",
    color: "#ffffff",
    zIndex: 12,
};

const profileRowStyle: CSSProperties = {
    paddingTop: "12px",
    lineHeight: "10px",
};

type Props = PropsFromState;

const Navigator: React.FC<Props> = (props) => {
    const { tokenData } = props;
    const navigate = useNavigate();

    const { currentTheme, navigatorHeight } = useTheme();

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const getEnvironmentColor = (): string => {
        switch (config.environment) {
            case "Development":
                return "#400CCC";
            case "Demo":
                return "#CC3F0C";
            case "Staging":
                return "#392AA5";
            default:
                return "#2597A3";
        }
    };

    const getEnvironmentName = (): string => {
        const version = `v${props.appInfo ? props.appInfo.version : ""}`;
        switch (config.environment) {
            case "Development":
                return "Dev";
            case "Demo":
                return `Demo ${version}`;
            case "Staging":
                return `Staging ${version}`;
            default:
                return `${version}`;
        }
    };

    const getMenuItemStyle = (item: RootNavigationItem): CSSProperties => {
        if (!item.isCurrent) return {};
        return {
            backgroundColor: `${item.color}`,
        };
    };

    const getEnvironmentNameLeft = (): string => {
        switch (config.environment) {
            case "Production":
                return "170px";
            case "Demo":
                return "130px";
            case "Staging":
                return "120px";
            default:
                return `180px`;
        }
    };

    const menuItems: MenuProps["items"] = props.rootNavigationItems
        .filter((item) =>
            hasPermissionsMenuGroups(
                props.menus[item.applicationId].groups,
                props.useCases,
                props.roles
            )
        )
        .map((item) => {
            return {
                label: item.name,
                key: item.applicationId,
                style: getMenuItemStyle(item),
                onClick: () => navigate(item.relativePath),
                icon: <Icon name={item.icon} style={{ fontSize: "16px" }} />,
            };
        });

    const background = currentTheme === "dark" ? colorBgContainer : undefined;
    const menuTheme = currentTheme === "dark" ? undefined : "dark";

    return (
        <Header
            style={{
                position: "fixed",
                zIndex: 3,
                width: "100%",
                borderBottomStyle: "solid",
                borderBottomColor: props.currentRootNavigationItem.color,
                borderBottomWidth: 5,
                height: navigatorHeight,
                background: background,
            }}
        >
            <div style={appNameStyle}>
                <img src={logoLight} width={120} style={{ marginLeft: -30, marginTop: 16 }} />
                <Tag
                    style={{
                        position: "absolute",
                        top: "35px",
                        left: getEnvironmentNameLeft(),
                    }}
                    color={getEnvironmentColor()}
                >
                    {getEnvironmentName()}
                </Tag>
            </div>
            <div style={settingsStyle}>
                <Settings />
            </div>
            <div style={profileStyle}>
                <Popover
                    placement="bottomLeft"
                    content={<IdentityStatus />}
                    title="Profile"
                    mouseEnterDelay={0.5}
                >
                    <div>
                        <div style={profileRowStyle}>
                            {tokenData ? `${tokenData.firstName} ${tokenData.lastName}` : ""}
                        </div>
                        <div style={profileRowStyle}>
                            {tokenData ? tokenData.organisationName : ""}
                        </div>
                    </div>
                </Popover>
            </div>
            <Menu
                theme={menuTheme}
                mode="horizontal"
                items={menuItems}
                style={{ background: background }}
            />
        </Header>
    );
};

type PropsFromState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: RootState) => {
    const contextState = contextSelector(state);
    return {
        menus: menusSelector(state),
        rootNavigationItems: rootNavigationItemsSelector(state),
        currentRootNavigationItem: currentRootNavigationItemSelector(state) || {},
        useCases: usecaseSelector(state),
        roles: roleSelector(state),
        tokenData: tokenDataSelector(state),
        appInfo: contextState.appInfo,
    };
};

export default connect(mapStateToProps)(Navigator);
