import { List, Select } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { signOut } from "@/state/auth";
import { Button, Drawer } from "@/ui/controls";
import { BgColorsOutlined, SettingOutlined } from "@ant-design/icons";

import { ThemeName } from "@/state/context";
import { useTheme } from "../hooks";

export const Settings: React.FC = () => {
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);

    const { themeOptions, currentTheme, setCurrentTheme } = useTheme();

    const onSignOut = () => {
        dispatch(signOut());
    };

    const data = [
        {
            name: "Theme",
            input: (
                <Select
                    value={currentTheme}
                    onChange={(value: ThemeName) => {
                        setCurrentTheme(value);
                    }}
                    style={{ width: 150 }}
                >
                    {themeOptions.map((option) => {
                        return (
                            <Select.Option key={option.value} value={option.value}>
                                {option.label}
                            </Select.Option>
                        );
                    })}
                </Select>
            ),
        },
    ];

    return (
        <>
            <SettingOutlined style={{ fontSize: 24 }} onClick={() => setVisible(true)} />
            <Drawer
                title="Settings"
                iconName="setting"
                visible={visible}
                onClose={() => setVisible(false)}
                footer={<Button onClick={() => setVisible(false)}>Close</Button>}
                width="500px"
            >
                <Button onClick={onSignOut} block={true} type="primary">
                    Signout
                </Button>

                <List
                    className="mt-8"
                    itemLayout="horizontal"
                    bordered={true}
                    size="large"
                    dataSource={data}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<BgColorsOutlined style={{ fontSize: 20 }} />}
                                title={item.name}
                            />
                            <div>{item.input}</div>
                        </List.Item>
                    )}
                />
            </Drawer>
        </>
    );
};
