// import { useThemeSwitcher } from "react-css-theme-switcher";

import { useTheme } from "./useTheme";

type ThemePalette = {
    textColour: string;
    backgroundColour: string;
    backgroundColourAlt: string;
};

const lightTheme: ThemePalette = {
    textColour: "rgba(0, 0, 0, 0.65)",
    backgroundColour: "#F0F2F5",
    backgroundColourAlt: "#FFFFFF",
};

const darkTheme: ThemePalette = {
    textColour: "rgba(255, 255, 255, 0.65)",
    backgroundColour: "#000000",
    backgroundColourAlt: "#1f1f1f",
};

export const useThemePalette = (): ThemePalette => {
    const { currentTheme } = useTheme();
    return currentTheme === "dark" ? darkTheme : lightTheme;
};
