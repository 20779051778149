import { Form as FormAD } from "antd";
import { FormProps } from "antd/lib/form";
import React from "react";
import { useSelector } from "react-redux";

import { hasRole, hasUseCase } from "@/app/identity";
import { roleSelector, usecaseSelector } from "@/state/auth";

import { FormContext } from "./state/FormContext";

type Props<T = object> = {
    readonly?: boolean;
    editUseCase?: string;
    editRole?: string;
    labelSpan?: number;
    children: React.ReactNode;
} & FormProps<T>;

const Form: <T>(p: Props<T>) => React.ReactElement<Props<T>> = (props) => {
    const useCases = useSelector(usecaseSelector);
    const roles = useSelector(roleSelector);

    const {
        readonly = false,
        editUseCase,
        editRole,
        labelSpan,
        layout = "horizontal",
        ...rest
    } = props;

    let ro = readonly;
    if (editUseCase) ro = !hasUseCase(props.editUseCase, useCases);

    if (editRole) ro = !hasRole(props.editRole, roles);

    let lblSpan = 6;
    if (labelSpan) lblSpan = labelSpan;

    const formItemLayout =
        layout === "horizontal"
            ? {
                  labelCol: { span: lblSpan },
                  wrapperCol: { span: 24 - lblSpan },
              }
            : null;

    return (
        <FormContext.Provider value={{ readonly: ro, ...formItemLayout }}>
            <FormAD layout={layout} {...rest}>
                {props.children}
            </FormAD>
        </FormContext.Provider>
    );
};

export { Form };
