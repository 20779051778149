import { PolicyNewBusinessTypesListState } from "../types";
import { PolicyNewBusinessTypeListAction } from "./actions";

export const defaultState: PolicyNewBusinessTypesListState = {
    items: [],
};

export const reducer = (
    state: PolicyNewBusinessTypesListState = defaultState,
    action: PolicyNewBusinessTypeListAction
): PolicyNewBusinessTypesListState => {
    switch (action.type) {
        case "POLICYNEWBUSINESSTYPES_LIST_RECEIVE": {
            return {
                ...state,
                items: action.payload,
            };
        }
        default:
            return state;
    }
};
