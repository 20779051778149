import { Card, Skeleton } from "antd";
import React from "react";
import { connect } from "react-redux";

import { hasUseCase } from "@/app/identity";
import { IconName } from "@/app/types";
import { RootState } from "@/state";
import { usecaseSelector } from "@/state/auth";

import { Icon } from "../common/Icon";

type Props = {
    title: string;
    titleExtra?: React.ReactNode;
    iconName: IconName;
    onClick?: () => void;
    isLoading: boolean;
    actions?: React.ReactNode[];
    children: React.ReactNode;
    requiredUseCase?: string;
    rows?: number;
    vertialAlign?: boolean;
} & PropsFromState;

const PreviewCardComponent: React.FC<Props> = (props: Props) => {
    const {
        title,
        titleExtra,
        onClick,
        actions,
        isLoading,
        requiredUseCase,
        rows = 1,
        iconName,
        vertialAlign,
    } = props;

    let visible = true;
    if (requiredUseCase) visible = hasUseCase(requiredUseCase, props.useCases) && visible;

    if (!visible) return null;

    return (
        <Card
            title={
                <>
                    <span>
                        <span style={{ marginRight: "6px" }}>
                            <Icon name={iconName} />
                        </span>
                        {title}
                    </span>
                    {titleExtra && <span className="float-right">{titleExtra}</span>}
                </>
            }
            onClick={onClick}
            actions={actions}
            style={{
                display: "flex",
                flexDirection: "column",
            }}
            bodyStyle={{
                flexGrow: 1,
                display: "flex",
                alignItems: vertialAlign ? "center" : "start",
                justifyContent: "center",
            }}
        >
            <Skeleton
                loading={isLoading}
                title={false}
                active
                paragraph={{
                    rows: rows,
                }}
            >
                <div className="w-full">{props.children}</div>
            </Skeleton>
        </Card>
    );
};

type PropsFromState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: RootState) => {
    return {
        useCases: usecaseSelector(state),
    };
};

const PreviewCard = connect(mapStateToProps)(PreviewCardComponent);

export { PreviewCard };
