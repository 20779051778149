import { DeactivationReasonListState } from "../types";
import { DeactivationReasonListAction } from "./actions";

export const defaultState: DeactivationReasonListState = {
    items: [],
};

export const reducer = (
    state: DeactivationReasonListState = defaultState,
    action: DeactivationReasonListAction
): DeactivationReasonListState => {
    switch (action.type) {
        case "DEACTIVATIONREASON_LIST_RECEIVE": {
            return {
                ...state,
                items: action.payload,
            };
        }
        default:
            return state;
    }
};
