import { ComplianceRecordType } from "../types";

type ComplianceRecordTypeListReceiveAction = {
    type: "COMPLIANCERECORDTYPE_LIST_RECEIVE";
    payload: ComplianceRecordType[];
};

export type ComplianceRecordTypeListAction = ComplianceRecordTypeListReceiveAction;

export const receiveComplianceRecordTypes = (
    payload: ComplianceRecordType[]
): ComplianceRecordTypeListAction => ({
    type: "COMPLIANCERECORDTYPE_LIST_RECEIVE",
    payload,
});
