import { DatePicker } from "antd";
import { FilterConfirmProps } from "antd/lib/table/interface";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { Button } from "../";

const { RangePicker } = DatePicker;

export type DateRangeSearchPicker = "month" | "week" | "year";

type Props = {
    picker?: DateRangeSearchPicker;
    setSelectedKeys?: (selectedKeys: string[]) => void;
    selectedKeys: React.ReactText[];
    confirm?: (props: FilterConfirmProps) => void;
    clearFilters?: (selectedKeys: string[]) => void;
};

const DateRangeSearch: React.FC<Props> = (props: Props) => {
    const getDayjsValues = (): [Dayjs | null, Dayjs | null] => {
        if (!props.selectedKeys || props.selectedKeys.length !== 2) return [null, null];
        return [dayjs(props.selectedKeys[0]), dayjs(props.selectedKeys[1])];
    };

    return (
        <div style={{ padding: 8, width: 270 }}>
            <RangePicker
                picker={props.picker}
                onChange={(_dates: never, dateStrings: [string, string]) => {
                    if (props.setSelectedKeys) props.setSelectedKeys(dateStrings);
                }}
                style={{
                    marginBottom: 8,
                }}
                value={getDayjsValues()}
            />
            <Button
                type="primary"
                onClick={() => {
                    if (props.confirm) props.confirm({ closeDropdown: true });
                }}
                iconName="search"
                size="small"
                style={{ width: 123, marginRight: 8 }}
            >
                Search
            </Button>
            <Button
                onClick={() => {
                    if (props.clearFilters) props.clearFilters([]);
                }}
                size="small"
                style={{ width: 123 }}
            >
                Reset
            </Button>
        </div>
    );
};

export { DateRangeSearch };
