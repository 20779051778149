import { createSelector } from "reselect";

import { RootState } from "@/state";

import { ComplianceRecordTypeListState } from "../types";

const rootSelector = (state: RootState): ComplianceRecordTypeListState =>
    state.compliance.lookups.complianceRecordTypes.list;

export const complianceRecordTypesSelector: (state: RootState) => ComplianceRecordTypeListState =
    createSelector(rootSelector, (root) => root);
